import { configureStore, createSlice } from "@reduxjs/toolkit";

export const signUpSlice = createSlice({
  name: "signup",
  initialState: {
    open: false,
    name: "",
    company: "",
    email: "",
    role: null,
  },
  reducers: {
    toggleModal: (state) => {
      state.open = !state.open;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});
export const { toggleModal, setName, setCompany, setEmail, setRole } =
  signUpSlice.actions;

export default configureStore({
  reducer: {
    signUp: signUpSlice.reducer,
  },
});
